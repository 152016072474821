import store from 'store';

import { logger } from '@src/services';
import { env } from '@src/utils';

class RSIBrowserStorage {
  public readonly version: string = env.VERSION;

  public readonly isSessionStorageEnabled: boolean = false;

  private storageMap: Map<string, any> = new Map();

  private cookiesMap: Map<string, any> = new Map();

  constructor() {
    logger.info('Module mounted');

    try {
      sessionStorage.setItem('storageCheck', '1');
      sessionStorage.removeItem('storageCheck');
      this.isSessionStorageEnabled = true;
    } catch (error) {
      logger.warn('Unable to access session storage', error);
    }

    logger.debug('Initialized', {
      sessionStorageEnabled: this.isSessionStorageEnabled,
      fallbackStoreEnabled: store.enabled,
    });
  }

  public has(key: string): boolean {
    if (this.isSessionStorageEnabled) {
      return !!sessionStorage.getItem(key);
    }

    if (store.enabled) {
      return !!store.get(key);
    }

    return this.storageMap.has(key);
  }

  public set(key: string, value: any): void {
    if (!key) {
      throw new TypeError('key is missing');
    }

    if (value && Object.prototype.hasOwnProperty.call(value, 'expires')) {
      // eslint-disable-next-line no-param-reassign
      value = {
        ...value,
        expires: value.expires * 1000,
        time: new Date().getTime(),
      };
    }

    if (this.isSessionStorageEnabled) {
      const setOrRemove = (formattedValue: any) => {
        if (formattedValue) {
          sessionStorage.setItem(key, formattedValue);
        } else {
          sessionStorage.removeItem(key);
        }
      };

      try {
        setOrRemove(JSON.stringify(value));
      } catch (_) {
        try {
          setOrRemove(value);
        } catch (error) {
          logger.error(`[sessionStorage] Failed to ${value ? 'set' : 'remove'} value`, {
            key,
            value,
            error: (error instanceof Error && error.toString()) || error,
          });
        }
      }
    } else if (store.enabled) {
      store.set(key, value);
    } else {
      this.storageMap.set(key, value);
    }
  }

  public get(key: string): any {
    if (!key) {
      throw new TypeError('key is missing');
    }

    if (this.isSessionStorageEnabled) {
      const value = sessionStorage.getItem(key);

      if (!value) {
        return value;
      }

      try {
        return this.removeIfExpired(key, JSON.parse(value));
      } catch (_) {
        return this.removeIfExpired(key, value);
      }
    }

    if (store.enabled) {
      return this.removeIfExpired(key, store.get(key));
    }

    return this.removeIfExpired(key, this.storageMap.get(key));
  }

  private removeIfExpired(key: string, value: any): any {
    try {
      if (
        Object.prototype.hasOwnProperty.call(value, 'expires')
        && new Date().getTime() - value.time > value.expires
      ) {
        this.remove(key);
        return null;
      }

      if (Object.prototype.hasOwnProperty.call(value, 'value')) {
        return value.value;
      }
    } catch (error) {
      logger.error('[get] checkIsExpired failed', {
        error: (error instanceof Error && error.toString()) || error,
        value,
      });
    }

    return value;
  }

  /**
   * Removes key-value pair
   * @param key Key to remove
   */
  public remove(key: string): void {
    if (!key) {
      throw new TypeError('key is missing');
    }

    if (this.isSessionStorageEnabled) {
      try {
        sessionStorage.removeItem(key);
      } catch (error) {
        logger.error('[remove] Failed to remove item from sessionStorage', {
          error: (error instanceof Error && error.toString()) || error,
          stack: (error instanceof Error && error.stack) || null,
        });
      }
    } else if (store.enabled) {
      store.remove(key);
    } else {
      this.storageMap.delete(key);
    }
  }

  public clear(): void {
    if (this.isSessionStorageEnabled) {
      sessionStorage.clear();
    } else if (store.enabled) {
      store.clearAll();
    } else {
      this.storageMap.clear();
    }
  }

  public setCookie(name: string, value = '', expiryDays = 0, domain = '', path = ''): void {
    if (!name) {
      throw new TypeError('name is missing');
    }

    if (store.enabled) {
      try {
        let expires = '';
        if (expiryDays !== 0) {
          const d = new Date();
          d.setTime(d.getTime() + ((expiryDays) * 24 * 60 * 60 * 1000));
          expires = `;expires=${d.toUTCString()}`;
        }

        const domainString = domain ? `;domain=${domain}` : '';
        const pathString = path ? `;path=${path}` : '';
        document.cookie = `${name}=${value}${expires}${domainString}${pathString}`;
      } catch (error) {
        logger.error('[setCookie] Failed to set document.cookie', {
          name,
          value,
          expiryDays,
          domain,
          path,
          error: (error instanceof Error && error.toString()) || error,
        });
      }
    } else if (value) {
      this.cookiesMap.set(name, value);
    } else {
      this.cookiesMap.delete(name);
    }
  }

  public getCookie(name: string): string | undefined {
    if (!name) {
      throw new TypeError('name is missing');
    }

    let value: string | undefined;

    if (typeof document.cookie !== 'undefined') {
      const splitCookie = document.cookie.split(';');
      const crumb = splitCookie.find((c) => c.trim().startsWith(name));

      value = crumb?.trim().substring(name.length + 1, crumb.length);
    }

    if (value === undefined) {
      value = this.cookiesMap.get(name);
    }

    return value;
  }

  public deleteCookie(name: string): void {
    if (!name) {
      throw new TypeError('name is missing');
    }

    this.setCookie(name, '', -1);
  }
}

export default RSIBrowserStorage;
