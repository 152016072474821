import { env } from '@src/utils';

// Rewriting logger from template due to browser storage being loaded in first
const logger = new Proxy(console, {
  get(target: any, property) {
    const originalCall: any = target[property];

    if (typeof property === 'string' && ['debug', 'error', 'info', 'log', 'trace', 'warn'].includes(property)) {
      return new Proxy(originalCall, {
        apply(targetMethod, thisArg, argArray) {
          argArray.unshift(`[${env.TEAM}][${env.NAME}]`);

          return targetMethod.apply(thisArg, argArray);
        },
      });
    }

    return originalCall;
  },
});

export default logger;
